/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 4-11-2016
 * Time: 13:22
 *
 * de approuter service regelt het routen tussen de verchillende schermen
 * Applicatiespecifiek!
 * het is een laag om ui.router en api heen en regelt ook de beveiligde states (een state met .openbaar op false)
 * de config wordt gebrukt om de states te definieren
 *
 * Gebruik deze in plaats van de urlRouter en stateService:
 * approuterProvider.state(...)
 *   extra in de definitie:
 *   openbaar: [boolean]. Bij niet openbaar wordt gekeken of er wel is ingelogd (api.isLoggedIn)
 *   openbare_landing: [boolean]. als true, en de state is openbaar, dan zal naar deze state worden gesprongen als men naar een niet openbare state probeert te gaan
 *   indexes: [array]. Bepaalde indexen waarop gezocht kan worden met approuter.getByIndex of approuter.goToIndex. Zie daar

 *
 *
 *  approuter.go: vervangt $state.go. Zorgt dat approuter.src werkt
 */

angular.module('dl.approuter', [
    'ui.router',
    'pascalprecht.translate',
    'sihw.sihwlog',
    'dl.api'])
//algemene config router
    .config(['$urlRouterProvider', function ($urlRouterProvider) {
        $urlRouterProvider.otherwise('/');
    }])
    .provider('approuter', ['$stateProvider', function ($stateProvider) {
        var openbarelanding = false; //statename als er een openbare state is met flag openbare_landing true
        var opIndex = {};
        //provider, als approuterProvider tijdens de config
        var provider = {
            /**
             * Wrapper om $stateProvider.state(..). Met extra opties in de definitie:
             * - openbaar: als true, dan hoeft api.isLoggedIn niet te slagen om deze state te openen
             * @param name
             * @param statedef
             * @return {provider} chaining
             */
            state: function (name, statedef) {
                var def = angular.extend({}, statedef); //geen gerommel in aanroepend object
                def.params = def.params || {};
                def.params._approuter_src = "unknown"; //wordt gezet door onze go e.d., zie approuter.src()
                if (!(def.abstract || def.openbaar)) {
                    //deze state moet beveiligd worden
                    def.resolve = def.resolve || {};
                    //resolver moet een andere naam hebben dan deze service, anders kan de service niet in een controller injected worden
                    def.resolve._approuter_resolver = ['$stateParams', 'approuter', function ($stateParams, approuter) { //inject onze eigen service en de stateparams als dynamisch object
                        return approuter.resolveState(name, $stateParams);
                    }];
                }
                if (def.openbare_landing && def.openbaar) {
                    //hier gaan we heen als een state verboden is
                    openbarelanding = name;
                }
                //indexes? Zie getByIndex / gotoIndex
                statedef.index = statedef.index || [];
                statedef.index.forEach(function (i) {
                    opIndex[i] = opIndex[i] || [];
                    opIndex[i].push(name);
                });

                // console.log("Add state",name,statedef);
                $stateProvider.state(name, def);
                return this;
            }
        };

        //service, als approuter service na de config
        provider.$get = ['$rootScope', '$state', '$q', '$stateParams', '$translate', 'sihwlog', 'api', function ($rootScope, $state, $q, $stateParams, $translate, sihwlog, api) {
            //private
            var log = sihwlog.logLevel('debug');
            log.log('INIT APPROUTER');
            //init rootscope
            $rootScope.menu = {
                titel: 'Dynalearn Admin'
            };
            //service
            var service = {
                /**
                 * Stateresolver ACL. Checkt zelf op openbaar, dus kan rustig aan alle states gekoppeld worden.
                 * @param statename naam van de state
                 * @param stateparams de stateparams op het moment van resolven
                 * Ernstig versimpeld. Ingelogd = je mag erbij. De states kijken zelf maar
                 */
                resolveState: function (statename, $stateParams) {
                    var $this = this;
                    var nextstate = $state.get(statename);
                    $this.resetMenu();

                    return $q(function (resolve, reject) {
                        //openbaar?
                        if (nextstate.openbaar) {
                            resolve(true);
                            return; //klaar
                        }

                        api.isLoggedIn().then(function (gelukt) {
                            if (gelukt) {
                                resolve(true);
                            }
                            else {
                                reject(false); //rejecten: niet naar state
                                return $this.goOpenbaar();
                            }
                        }, reject);
                    });
                },

                /**
                 * Wrapper om $state.go, voegt isGo param toe
                 * @param to
                 * @param [toParams]
                 * @param [options]
                 * @returns {Promise} Resultaat van $state.go
                 */
                go: function (to, toParams, options) {
                    toParams = toParams || {};
                    toParams._approuter_src = "go";
                    return $state.go(to, toParams, options).catch(function (e) {
                    });
                },

                /**
                 * Vind de namen van de states met de opgegeven index (string in indexes-eigenschap)
                 * @param index
                 * @return {Array.<T>}
                 */
                getByIndex: function (index) {
                    return (opIndex[index] || []).slice(0);   //altijd een kopie
                },

                /**
                 * Ga naar de eerste state met de opgegeven index
                 * @param index
                 * @return {Promise} De promise van onze go als de state bestaat, een rejected promise met "not found" als de state niet gevonden is
                 */
                goToIndex: function (index) {
                    var states = opIndex[index];
                    return (states && states[0]) ? this.go(states[0]) : $q.reject('not found');
                },

                /**
                 * Ga naar de als openbare landing gedefinieerde state
                 * @return {Promise} resolvt/reject als this.go, reject als er geen openbare landing is
                 */
                goOpenbaar: function () {
                    if (openbarelanding) {
                        return this.go(openbarelanding);
                    }
                    else {
                        log.warn('Aanroep goOpenbaar zonder dat er een openbare_landing state is');
                        return $q.reject(false);
                    }
                },

                /**
                 * Geef de door ons opgeslagen bron van deze state terug. Is momenteel "unknown" of "go"
                 * @return {string|string}
                 */
                src: function () {
                    return $stateParams._approuter_src || "unknown";
                },

                /**
                 * Geeft true als de door ons opgeslagen bron van de huidige state onze go of goToIndex methode is
                 * @return {boolean}
                 */
                isGo: function () {
                    return $stateParams._approuter_src && $stateParams._approuter_src == "go";
                },

                /**
                 * Return de huidige statename
                 * @return {string}
                 */
                current: function () {
                    return $state.current;
                },

                //////zeer appspecifiek

                /**
                 * Ga naar het juiste hoofdscherm, gegeven de userpermissies
                 * @return {*|Promise}
                 * Sterk versimpeld
                 */
                mainScreen: function () {
                    var userdata = api.getUserdata();
                    if (!userdata) {
                        return this.goOpenbaar(); //naar het openbare scherm
                    }
                    log.log('userdata', userdata);

                    var domein = userdata.userdomein ||
                        (userdata.caps.projectadmin.length && userdata.caps.projectadmin[0].domeinid);
                    if (!domein) {
                        //uitloggen
                        api.logout();
                        this.goOpenbaar();
                    }
                    else {
                        //domeinadmin?
                        if (userdata.caps.superadmin || userdata.caps.domeinadmin.length) {
                            return this.go('domein', {domein: domein});
                        }
                        //anders dus projectadmin?
                        if (userdata.caps.projectadmin.length)
                        {
                            return this.go('project',{project: userdata.caps.projectadmin[0].id});
                        }
                        //anders nergens heen?
                        api.logout();
                        this.goOpenbaar();
                    }
                },

                logout: function () {
                    //vanuit rootscope (zie app.js): uitloggen
                    api.logout();
                    this.goOpenbaar(); //naar de openbare landing
                },

                ////menu zetten
                /**
                 * Intern: reset het menu op zijn defaults
                 */
                resetMenu: function () {
                    $rootScope.menu = {
                        titel: 'Dynalearn Admin'
                    }
                },
                /**
                 * Zet de menutitel voor de huidige state. Wordt gereset bij een volgende state
                 * @param {string} titel met translate te filteren titel
                 * @param {string} [literal] letterlijk achtervoegsel, wordt niet vertaald
                 */
                menutitel: function (titel, literal) {
                    $rootScope.menu.titel = $translate.instant(titel);
                    if (literal) {
                        $rootScope.menu.titel += " " + literal;
                    }
                    $rootScope.menu.titel += ' - Dynalearn Admin';

                    console.log("TITEL",$rootScope.menu.titel);
                },

                /**
                 * Zet dit domein als het nu gebruikte, voor in het menu.
                 * @param domein de id van het te gebruiken domein, (of het hele object) of false als niets
                 */
                menudomein: function(domein)
                {
                    //zet dit domein als het nu gebruikte
                    if (angular.isObject(domein) && domein.id)
                    {
                        $rootScope.menu.domein = domein.id;
                    }
                    else {
                        $rootScope.menu.domein = domein;
                    }
                    //we vullen ook alle beschikbare projecten
                    $rootScope.menu.domeinprojecten = [];
                    //en op actief en inactief
                    $rootScope.menu.domeinprojecten_actief = [];
                    $rootScope.menu.domeinprojecten_inactief = [];
                    api.projecten( $rootScope.menu.domein).then(function (projecten) {
                        angular.forEach(projecten, function(pr) {
                            $rootScope.menu.domeinprojecten.push(pr);
                            $rootScope.menu[pr.actief ? 'domeinprojecten_actief' : 'domeinprojecten_inactief'].push(pr);
                        });
                    });
                },

                /**
                 * Zet dit project als het nu gebruikte, voor in het menu
                 * @param {object} project is het hele projectrecord
                 * @param project
                 */
                menuproject: function(project)
                {
                    $rootScope.menu.project = project;
                }
            };
            return service;
        }];
        return provider;
    }]);
